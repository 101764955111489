import React, { useContext } from 'react';
import { compose, values, uniq } from 'ramda';
import { useHistory } from 'react-router-dom';
import { LanguageContext } from '../../../../providers/Language';
import { LanguageSelector } from '../../../../components';

import './Footer.css';

const Footer = () => {
  const {
    messages: { footer },
    lang,
    languages,
    switchLang,
  } = useContext(LanguageContext);

  const history = useHistory();

  const onLanguageChange = (lang) => {
    switchLang(lang);
    history.push(`/${lang}/login`);
  };

  const uniqSupportedLanguages = compose(
    uniq,
    values,
  )(languages);

  return (
    <footer className="footer-container">
      <a
        href="https://www.ecit.com/about-ecit"
        target="_new"
        className="footer-item"
      >
        {footer.aboutEcit}
      </a>

      <a
        href="https://www.ecit.com/privacy-policy"
        target="_new"
        className="footer-item"
      >
        {footer.aboutPrivacyPolicy}
      </a>

      <a
        href="https://www.ecit.com/cookie"
        target="_new"
        className="footer-item"
      >
        {footer.aboutCookies}
      </a>

      <span className="footer-item">
        <LanguageSelector
          lang={lang}
          languages={uniqSupportedLanguages}
          onLanguageChange={onLanguageChange}
        />
      </span>
    </footer>
  );
};

export default Footer;
