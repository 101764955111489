import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
} from 'react-router-dom';
import {
  Home,
  Login,
  Callback,
  AuthorizationError,
  RouteWithLayout,
} from './components';
import {
  Main as MainLayout,
  Minimal as MinimalLayout,
} from './layouts';

import { LanguageProvider } from './providers/Language';
import { AuthProvider } from './providers/Auth';

const Routes = () => (
  <Router>
    <LanguageProvider>
      <AuthProvider>
        <Switch>
          <RouteWithLayout
            exact
            path="/"
            layout={MainLayout}
            component={Home}
          />
          <RouteWithLayout
            exact
            path="/:locale(en|sv|nb|no|da)+/login"
            layout={MainLayout}
            component={Login}
          />
          <RouteWithLayout
            exact
            path="/callback"
            layout={MainLayout}
            component={Callback}
          />
          <RouteWithLayout
            exact
            path="/:locale(en|sv|nb|no|da)+"
            layout={MainLayout}
            component={(props) => <Redirect to={`/${props.match.params.locale}/login`} />}
          />
          <RouteWithLayout
            exact
            path="/:locale(en|sv|nb|no|da)+/authorization-error"
            layout={MinimalLayout}
            component={AuthorizationError}
          />

          <Redirect to="/en/login" />
        </Switch>
      </AuthProvider>
    </LanguageProvider>
  </Router>
);

export default Routes;
