import Auth0Lock from 'auth0-lock';
import {
  compose,
  map,
  reduce,
  allPass,
  isEmpty,
  not,
  pathSatisfies,
  path,
} from 'ramda';
import {
  AUTH_CONFIG,
  AUTH_LOCK_OPTIONS,
  AUTH_LANGUAGE_DICTIONARY,
  AUTH_FORGET_PASS_BY_LANG,
} from '../config/auth';

const drawColoredLine = () => {
  let divider = document.getElementsByClassName('divider')[0];
  if (divider) return;

  divider = document.createElement('div');
  divider.classList.add('divider');
  const header = document.querySelector('.auth0-lock-header');

  const colorized = compose(
    reduce((current, next) => {
      current.appendChild(next);
      return current;
    }, divider),
    map((color) => {
      const toColorize = document.createElement('div');
      toColorize.classList.add(color);
      return toColorize;
    }),
  )(['navy', 'blue', 'green']);

  header.parentNode.insertBefore(colorized, header.nextSibling);
};

class Auth {
  constructor() {
    this.lock = new Auth0Lock(
      AUTH_CONFIG.CLIENT_ID,
      AUTH_CONFIG.DOMAIN,
      AUTH_LOCK_OPTIONS,
    );

    // shown by default is false
    this.isLockShown = false;

    this.lock.on('show', drawColoredLine);
    this.lock.on('authenticated', this.onAuthenticated.bind(this));

    this.login = this.login.bind(this);
    this.authentication = this.authentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
  }

  login({ locale, ...options }) {
    // re-open with new language if it's already opened
    if (this.isLockShown) this.lock.hide();

    const opts = {
      ...options,
      languageDictionary: {
        // default language dictionary
        ...AUTH_LANGUAGE_DICTIONARY,
        // override the default with the selected language
        ...AUTH_FORGET_PASS_BY_LANG[options.language],
      },
      auth: {
        autoParseHash: false,
        redirectUrl: AUTH_CONFIG.REDIRECT_URL,
        responseType: 'token id_token',
        params: { scope: 'openid', state: locale },
      },
    };

    this.isLockShown = true;
    this.lock.show(opts);
  }

  authentication(hash) {
    return new Promise((resolve, reject) => {
      this.lock.resumeAuth(hash, (error, authResult) => {
        if (error) {
          reject(error);
          return;
        }

        resolve(authResult);
      });
    });
  }

  onAuthenticated(authResult) {
    const hasAccessToken = allPass([
      compose(
        not,
        isEmpty,
      ),
      pathSatisfies(['accessToken']),
      pathSatisfies(['idToken']),
    ])(authResult);

    const redirectAfterLoginUrl = path(
      ['idTokenPayload', AUTH_CONFIG.REDIRECT_AFTER_LOGIN_CLAIM],
      authResult,
    );

    if (hasAccessToken) {
      this.setSession(authResult);
      if (redirectAfterLoginUrl) {
        window.location.href = redirectAfterLoginUrl;
      }
    }
  }

  setSession({ accessToken }) {
    return new Promise((resolve, reject) => {
      this.lock.getUserInfo(accessToken, (error, profile) => {
        if (error) {
          // eslint-disable-next-line no-console
          console.log(error);
          reject(reject);
          return;
        }

        resolve(profile);
      });
    });
  }

  // eslint-disable-next-line
  isAuthenticated() {
    const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    return new Date().getTime() < expiresAt;
  }
}

export const auth = new Auth();
