import React, {
  useEffect,
  useContext,
  useState,
} from 'react';
import {
  compose,
  head,
  split,
} from 'ramda';
import { Redirect } from 'react-router-dom';
import { LanguageContext } from '../../providers/Language';
import { getBrowserLanguage } from '../../utils/browser-language';

const Home = () => {
  const {
    lang,
    switchLang,
    languages,
  } = useContext(LanguageContext);

  const [to, setRedirectTo] = useState(null);

  useEffect(() => {
    const defaultBrowserLanguage = getBrowserLanguage();

    const locale = compose(
      head,
      split(/(-|_)/),
    )(defaultBrowserLanguage);

    const language = languages[locale];

    if (!locale || !language) {
      setRedirectTo(lang);
      return;
    }

    switchLang(language);
    setRedirectTo(language);

    // eslint-disable-next-line
  }, []);

  return to && <Redirect to={`${to}/login`} />;
};

export default Home;
