import React from 'react';

import './Content.css';

const Content = ({ children }) => (
  <main className="main-content">
    {children}
  </main>
);

export default Content;
