import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import 'core-js/es/map';
import 'core-js/es/set';
import 'raf/polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';

import App from './routes';

import './index.css';
import 'bootstrap/dist/css/bootstrap.css';

Sentry.init({
  dsn: 'https://99d930ee83bf4f369fd89b99e71ae2b4@o163339.ingest.sentry.io/5222857',
});

ReactDOM.render(
  <App />,
  document.getElementById('root'),
);
