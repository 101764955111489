import React, { useContext, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import {
  allPass,
  propEq,
  compose,
  split,
  last,
} from 'ramda';
import { AuthContext } from '../../providers/Auth';
import loading from './loading.svg';

import './Callback.css';

const SERVER_ERROR = 'server_error';
const UNABLE_TO_CONFIGURE_VERIFICATION_PAGE = 'Unable to configure verification page.';
const isSafari = /.*Version.*Safari.*/.test(navigator.userAgent);
const isFirefox = typeof InstallTrigger !== 'undefined';

const Callback = () => {
  const { hash } = useLocation();
  const history = useHistory();
  const { authentication } = useContext(AuthContext);

  const authorize = async () => {
    if (!/access_token|id_token|error/.test(hash)) return;

    try {
      await authentication(hash);
    } catch (e) {
      const is3rdPartyCookieIssue = allPass([
        propEq('error', SERVER_ERROR),
        propEq('errorDescription', UNABLE_TO_CONFIGURE_VERIFICATION_PAGE),
        () => isSafari || isFirefox,
      ])(e);

      const locale = compose(
        last,
        split('='),
        last,
        split('&'),
      )(hash);

      history.push({
        pathname: is3rdPartyCookieIssue ? `/${locale}/authorization-error` : '/',
      });
    }
  };

  useEffect(() => {
    authorize();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="callback">
      <img src={loading} alt="loading" />
    </div>
  );
};

export default Callback;
