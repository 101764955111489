import React from 'react';
import { Header, Content, Footer } from './components';

const Main = ({ children }) => (
  <React.Fragment>
    <Header />
    <Content>
      {children}
    </Content>
    <Footer />
  </React.Fragment>
);

export default Main;
