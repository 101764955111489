import React from 'react';

import './Minimal.css';

const Minimal = ({ children }) => (
  <div className="minimal">
    {children}
  </div>
);

export default Minimal;
