import React, { useContext } from 'react';
import CookieBanner from 'react-cookie-banner';
import { LanguageContext } from '../../../../providers/Language';

import './Header.css';

const Header = () => {
  const { messages: { login } } = useContext(LanguageContext);

  return (
    <header>
      <CookieBanner
        message={login.cookieText}
        buttonMessage={login.cookieTextConfirm}
        cookie="user-has-accepted-cookies"
        className="react-cookie-banner-override"
      />
    </header>
  );
};

export default Header;
