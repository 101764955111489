import { cookie } from 'browser-cookie-lite';

const { protocol, host } = window.location;

export const AUTH_CONFIG = {
  REDIRECT_AFTER_LOGIN_CLAIM: 'https://ecitapps.com/redirectAfterLogin',
  DOMAIN: process.env.REACT_APP_AUTH_DOMAIN
    || cookie(process.env.REACT_APP_COOKIE_NAME_AUTH_DOMAIN),
  CLIENT_ID: process.env.REACT_APP_AUTH_CLIENT_ID
    || cookie(process.env.REACT_APP_COOKIE_NAME_AUTH_CLIENT_ID),
  REDIRECT_URL: `${protocol}//${host}/callback`,
};

export const AUTH_LANGUAGE_DICTIONARY = {
  forgotPasswordAction: 'Forgot password? ',
  forgotPasswordSubmitLabel: 'SEND',
  signUpTerms: 'I agree to the '
    + '<a href=\'/terms\' target=\'_new\'>terms of service</a> '
    + 'and '
    + '<a href=\'/privacy\' target=\'_new\'>privacy policy</a>.',
  title: '',
};

export const AUTH_LOCK_OPTIONS = {
  audience: `https://${AUTH_CONFIG.DOMAIN}/userinfo`,
  auth: {
    autoParseHash: false,
    redirectUrl: AUTH_CONFIG.REDIRECT_URL,
    responseType: 'token id_token',
    params: { scope: 'openid' },
  },
  configurationBaseUrl: 'https://cdn.eu.auth0.com',
  closable: false,
  languageDictionary: AUTH_LANGUAGE_DICTIONARY,
  container: 'auth0-container',
  theme: {
    logo: 'https://storage.googleapis.com/assets.ecitapps.com/images/ECIT_logo_v3-primary_positiveRGB_2021.svg',
    primaryColor: '##00FF00',
  },
};

export const AUTH_FORGET_PASS_BY_LANG = {
  en: {
    forgotPasswordAction: 'Forgot password?',
    forgotPasswordSubmitLabel: 'SEND',
  },
  sv: {
    forgotPasswordAction: 'Glömt ditt lösenord?',
    forgotPasswordSubmitLabel: 'SEND',
  },
  da: {
    forgotPasswordAction: 'Glemt din adgangskode?',
    forgotPasswordSubmitLabel: 'SEND',
  },
  nb: {
    forgotPasswordAction: 'Glemt ditt passord?',
    forgotPasswordSubmitLabel: 'Glemt passord?',
  },
};
