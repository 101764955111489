import React from 'react';
import { auth } from '../services/auth';

export const AuthContext = React.createContext({
  login: () => {},
  authentication: () => {},
  isAuthenticated: () => {},
});

export const AuthProvider = ({ children }) => {
  const login = (options) => auth.login(options);
  const authentication = (hash) => auth.authentication(hash);
  const isAuthenticated = () => auth.isAuthenticated();

  return (
    <AuthContext.Provider
      value={{
        login,
        authentication,
        isAuthenticated,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
