import React, { useState, createContext } from 'react';
import { withRouter } from 'react-router-dom';
import {
  drop,
  compose,
  head,
  split,
  path,
  includes,
  values,
} from 'ramda';

import * as messages from '../i18n';
import {
  LANGUAGES,
  DEFAULT_LANGUAGE,
} from '../config/lang';

export const LanguageContext = createContext({
  lang: DEFAULT_LANGUAGE,
  messages: {},
  switchLang: () => {},
  languages: {},
});

function LngProvider({ children, location }) {
  const [
    lang,
    changeLang,
  ] = useState(DEFAULT_LANGUAGE);

  const locale = compose(
    head,
    drop(1),
    split('/'),
    path(['pathname']),
  )(location);

  React.useEffect(() => {
    if (
      compose(
        includes(locale),
        values,
      )(LANGUAGES)
    ) {
      changeLang(locale);
    }
  }, [lang]);

  const switchLang = (language) => {
    changeLang(language);
  };

  return (
    <LanguageContext.Provider
      value={{
        lang,
        switchLang,
        messages: messages[lang],
        languages: LANGUAGES,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
}

export const LanguageProvider = withRouter(LngProvider)
