export default {
  footer: {
    aboutCookies: 'Cookies',
    aboutEcit: 'ECIT group',
    aboutPrivacyPolicy: 'Privacy Policy',
  },
  login: {
    cookieText: 'We rely on cookies to offer our services. '
      + 'By using our Website you permit us the use '
      + 'of cookies in accordance with our policy.',
    cookieTextConfirm: 'OK',
  },
  authCookiesError: {
    title: 'Login failed',
    description: 'Unfortunately we could not login you in at this time due to a technical error.'
      + ' This often happens when the web-browser have been upgraded and 3rd party'
      + ' cookies are disabled by default.',
    resolution: 'We are aware of this issue and are currently working on a fix.'
      + ' Meanwhile, you can login by going directly to',
  },
};
