export default {
  footer: {
    aboutCookies: 'Cookies',
    aboutEcit: 'ECIT group',
    aboutPrivacyPolicy: 'Privacy Policy',
  },
  login: {
    cookieText: 'We rely on cookies to offer our services. '
      + 'By using our Website you permit us the use '
      + 'of cookies in accordance with our policy.',
    cookieTextConfirm: 'OK',
  },
  authCookiesError: {
    title: 'Innlogging feilet',
    description: 'Pga. en teknisk feil var det ikke mulig å logge inn.'
      + ' Dette oppstår som oftest ved av at nettleseren nylig har blitt oppgradert,'
      + ' der tredjeparts \'cookies\' ikke tillates.',
    resolution: 'Vi er klar over feilen og vi jobber med en løsning.'
      + ' I mellomtiden er det mulig å logge inn dersom man går direkte til',
  },
};
