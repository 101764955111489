import React, { useEffect, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { LanguageContext } from '../../providers/Language';
import { AuthContext } from '../../providers/Auth';

import './Login.css';

const Login = () => {
  const { lang, switchLang, languages } = useContext(LanguageContext);
  const { login } = useContext(AuthContext);
  const { locale } = useParams();
  const history = useHistory();

  useEffect(() => {
    const language = languages[locale];

    if (!language) history.push('/');

    switchLang(language);
    login({ language, locale });

    // eslint-disable-next-line
  }, [lang])

  return <div id="auth0-container" />;
};

export default Login;
