export default {
  footer: {
    aboutCookies: 'Cookies',
    aboutEcit: 'ECIT Group',
    aboutPrivacyPolicy: 'Privacy Policy',
  },
  login: {
    cookieText: 'Vi använder cookies för att kunna erbjuda våra '
      + 'tjänster. Genom att fortsätta använda vår site  godkänner du detta.',
    cookieTextConfirm: 'Jag förstår',
  },
  authCookiesError: {
    title: 'Inloggningen har misslyckats ',
    description: 'Tyvärr har inloggningen misslyckats på grund av ett tekniskt fel.'
      + ' Detta uppstår ofta pga att webbläsaren blivit uppgraderad där'
      + ' tredjeparts \'cookies\' inte tillåts. ',
    resolution: 'Detta är ett känt fel och vi arbetar för att ta fram en lösning.'
      + ' Under tiden är det möjligt att logga in genom att gå direkt till',
  },
};
