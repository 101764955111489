import React from 'react';
import { Image } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { LanguageContext } from '../../providers/Language';

import './AuthorizationError.css';

import logo from './logo.svg';
import bird from './bird.svg';

const AuthorizationError = () => {
  const { locale } = useParams();
  const {
    messages: { authCookiesError },
    switchLang,
  } = React.useContext(LanguageContext);

  React.useEffect(() => {
    switchLang(locale);
  }, []);

  return (
    <div className="authorization-error-wrapper">
      <div className="authorization-error-dialog">
        <div className="authorization-error-logo">
          <Image src={logo} className />
        </div>
        <div className="authorization-error-divider">
          <div className="navy" />
          <div className="blue" />
          <div className="green" />
        </div>
        <div className="authorization-error-bird">
          <Image src={bird} />
        </div>
        <div className="authorization-error-title">
          {authCookiesError.title}
          &nbsp;
          :(
        </div>
        <div className="authorization-error-subtitle">
          {authCookiesError.description}
        </div>
        <div className="authorization-error-description">
          {authCookiesError.resolution}
          &nbsp;
          <a href="https://portal.ecitapps.com">
            https://portal.ecitapps.com
          </a>
        </div>
      </div>
    </div>
  );
};

export default AuthorizationError;
