import React from 'react';
import PropTypes from 'prop-types';
import { DropdownButton, MenuItem } from 'react-bootstrap';

import './LanguageSelector.css';
import './languages.css';

const LanguageSelector = ({ lang, languages, onLanguageChange }) => (
  <DropdownButton
    id="dropdown-lang"
    bsStyle="link"
    dropup
    title={(
      <i
        className="lang-xs lang-lbl"
        lang={lang}
      />
      )}
  >
    {languages.map((language) => (
      <MenuItem
        key={language}
        eventKey={language}
        onSelect={onLanguageChange}
      >
        <span className="lang-sm lang-lbl" lang={language} />
      </MenuItem>
    ))}
  </DropdownButton>
);

LanguageSelector.defaultProps = {
  languages: ['en'],
};

LanguageSelector.propTypes = {
  onLanguageChange: PropTypes.func.isRequired,
  languages: PropTypes.arrayOf(PropTypes.string),
  lang: PropTypes.string.isRequired,
};

export default LanguageSelector;
